import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Button,
	H2,
	Icon,
	IconPotentialIssue,
	Paragraph,
	TextContainer,
	Tooltip,
	Ul,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
	DocsTable,
} from "../../../../../src/docs";
import { InputField, InputFieldProps, InputFieldWithSlug } from "../input-field/input-field";
import { Select } from "../select/select";
import { CheckboxGroup } from "../checkbox/checkbox";
import { Radios } from "../radios/radios";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { FormElementWrapper } from "./form-element-wrapper";

export const Meta: DocPageMeta = {
	category: "Forms and input",
	title: "Form element wrapper",
	notepad: "https://hackmd.io/YXLPRMqrS_2ngel0qPyJ_w",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Form element wrapper"
			subTitle="Provides a structured container for form elements, clarifying their purpose, offering context, and displaying feedback consistently."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={FormElementWrapper} />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Overview of form elements</Header.H3>
				<DocsTable>
					<thead>
						<tr>
							<th>Control</th>
							<th>Usage</th>
							<th>Examples</th>
							<th>Best practice</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
									Form Element Wrapper
								</GatsbyLink>{" "}
							</td>
							<td>
								Wraps individual input controls to provide consistent styling, labeling, and error
								handling.
							</td>
							<td>A group of radio buttons with a label, help text, and an error message area.</td>
							<td>
								<Ul
									items={[
										"Group related elements together visually.",
										"Use clear and descriptive labels",
										<>
											Provide concise instructions and error messages in close proximity to the
											input field.
										</>,
									]}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink to="/lab/components/Forms and input/Input Field">
									Input Field
								</GatsbyLink>{" "}
							</td>
							<td>Captures short, single-line text.</td>
							<td>Name, email address, phone number, short answer</td>
							<td>
								<Ul
									items={[
										"Use the most appropriate input type for the data being collected.",
										"Ensure the label clearly describes the expected input.",
										<>Provide a placeholder as a hint or example.</>,
									]}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink>{" "}
							</td>
							<td>Allows users to select multiple options from a list.</td>
							<td>
								"Agree to terms and conditions," selecting rows to display in the table, bulk
								selection
							</td>
							<td>
								<Ul
									items={[
										"Group related options visually.",
										<>Consider offering a "Select All" option if applicable.</>,
									]}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink to="/lab/components/Forms and input/Radios">Radios</GatsbyLink>{" "}
							</td>
							<td>
								Allows users to select a single option from a list of mutually exclusive choices.
							</td>
							<td>Choosing WCAG version, level of WCAG conformance, or display title/ URL</td>
							<td>
								<Ul
									items={[
										"Limit the number of options to avoid overwhelming users.",
										"Visually group options and use clear, concise labels.",
										<>If appropriate, pre-select the most common or recommended option.</>,
									]}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink
									to="/lab/components/Actions and controls/Toggle Switch
"
								>
									Toggle Switch
								</GatsbyLink>{" "}
							</td>
							<td>Provides a binary choice (on/off, yes/no).</td>
							<td>
								Enabling/disabling an AI feature, showing/hiding a section of content, or toggling
								an enhanced contrast in Chart.
							</td>
							<td>
								<Ul
									items={[
										"Clearly label the on and off states.",
										"Use toggle switches sparingly to avoid overwhelming users",
									]}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink
									to="/lab/components/Forms and input/File Input

"
								>
									File Input
								</GatsbyLink>{" "}
							</td>
							<td>Allows users to upload one or more files.</td>
							<td>Uploading a company logo to customise dashboard report</td>
							<td>
								<Ul
									items={[
										"Clearly indicate accepted file types and size limits. ",
										"Display the selected file name or a preview after selection.",
									]}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink
									to="/lab/components/Forms and input/Select
"
								>
									Select
								</GatsbyLink>{" "}
							</td>
							<td>
								Allows users to choose a single option (or multiple in a multi-select variant) from
								a list.
							</td>
							<td>Selecting a country, state, or category from a predefined list.</td>
							<td>
								<Ul
									items={[
										"Use a descriptive label for the Select.",
										"Order options logically (alphabetically, numerically, or by importance).",
										"Consider a searchable Select for long lists.",
									]}
								/>
							</td>
						</tr>
						<tr>
							<td>
								<GatsbyLink
									to="/lab/components/Forms and input/Text area
"
								>
									Text area
								</GatsbyLink>{" "}
							</td>
							<td>Captures longer, multi-line text input.</td>
							<td>
								Writing a comment, Chart’s annotation providing feedback, or filling out a long-form
								description.
							</td>
							<td>
								<Ul
									items={[
										"Provide enough space for the expected input.",
										"Consider showing a character count or limit.",
										"Use placeholder text as a hint or example.",
									]}
								/>
							</td>
						</tr>
					</tbody>
				</DocsTable>
				<Header.H3>Default</Header.H3>
				<Paragraph>The default wrapper is ideal for text input fields.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>
							Use specific and descriptive labels (e.g., "Mailing Address" instead of just
							"Address").
						</>,
						<>
							Consider using placeholder text to provide examples or hints, but <b>not</b> as a
							replacement for labels.
						</>,
					]}
				/>
				<Example fn={DefaultExample} />
				<Header.H3>Group</Header.H3>
				<Paragraph>
					If you need to use another component (e.g. a{" "}
					<GatsbyLink to="/lib/components/Actions and controls/Button">Button</GatsbyLink>) together
					with a form control you must pass a child with the type of a functional component, spread
					the props on the form control component (e.g.{" "}
					<GatsbyLink to="/lab/components/Forms and input/Input Field">Input Field</GatsbyLink>) and
					add the <Code>isGroup</Code> prop. This will ensure that the correct <Code>aria</Code>{" "}
					attributes are being added in the right places.{" "}
				</Paragraph>
				<Example fn={GroupExample} />
				<Header.H3>Single Select</Header.H3>
				<Paragraph>Allows users to choose one option from a list.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Use a clear label to describe the options in the list.</>,
						<>
							If there are only a few options, consider using{" "}
							<GatsbyLink to="/lab/components/Forms and input/Radios">Radios</GatsbyLink> instead
							for improved visibility.
						</>,
					]}
				/>
				<Example fn={SingleSelectExample} />
				<Header.H3>Multi Select</Header.H3>
				<Paragraph>Allows users to choose multiple options from a list.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Follow the same guidelines as single select.</>,
						<>Clearly indicate how many options the user can select (e.g., "Select up to 3").</>,
					]}
				/>
				<Example fn={MultiSelectExample} />
				<Header.H3>Checkbox</Header.H3>
				<Paragraph>Allows users to select multiple options from a set of checkboxes.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Use a group label to explain the relationship between the checkboxes.</>,
						<>Ensure each checkbox has a clear and concise label.</>,
					]}
				/>
				<Example fn={CheckboxExample} />
				<Header.H3>Radio button</Header.H3>
				<Paragraph>
					Allows users to select a single option from a set of mutually exclusive choices.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Use radio buttons for a limited number of options.</>,
						<>Ensure each radio button has a clear and concise label.</>,
						<>Group related radio buttons.</>,
					]}
				/>
				<Example fn={RadiobuttonExample} />
				<Header.H3>Helptext</Header.H3>
				<Paragraph>Provide additional context and immediate feedback to the user.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Use concise help text to guide users.</>,
						<>Avoid excessive placeholder texts.</>,
					]}
				/>
				<Example fn={HelptextExample} />
				<Header.H3>Invalid state</Header.H3>
				<Paragraph>Provide additional context and immediate feedback to the user.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Validate input in real-time and provide clear error messages.</>,
						<>Use visual cues (color, icons) to indicate the state of the input (e.g error).</>,
					]}
				/>
				<Example fn={InvalidStateExample} />
				<Header.H3>Helptext and invalid state</Header.H3>
				<Paragraph>Provide additional context and immediate feedback to the user.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Use concise help text to guide users.</>,
						<>Validate input in real-time and provide clear error messages.</>,
						<>Use visual cues (color and icons) to indicate the state of the input (e.g error).</>,
					]}
				/>
				<Example fn={HelptextAndInvalidStateExample} />
				<Header.H3>Horizontal</Header.H3>
				<Paragraph>Arrange labels horizontally next to their corresponding input fields.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Use for forms with limited space or shorter labels.</>,
						<>Ensure labels and inputs are visually aligned.</>,
					]}
				/>
				<Example fn={HorizontalExample} />
				<Header.H3>Input with slugs, helptext and invalid state</Header.H3>
				<Paragraph>Provide additional context and immediate feedback to the user.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Use concise help text to guide users.</>,
						<>Validate input in real-time and provide clear error messages.</>,
						<>Use visual cues (color and icons) to indicate the state of the input (error).</>,
					]}
				/>
				<Example fn={SlugsHelptextAndInvalidStatExample} />
				<Header.H3>Tooltip variants</Header.H3>
				<Paragraph>Provide additional information on hover or focus.</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:{" "}
				</Paragraph>
				<Ul
					items={[
						<>Use tooltips sparingly for additional information that doesn't fit in the label.</>,
						<>Keep tooltip content brief and relevant.</>,
					]}
				/>
				<Example fn={TooltipVariantsExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={FormElementWrapper}
					initialProps={{
						label: "Some label",
						name: "some name",
						children: <ExampleInputField />,
					}}
				/>

				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>FormElementWrapper</Code> when
					</Paragraph>
					<Ul
						items={[
							<>
								Creating individual form elements (input fields, selects, checkboxes, radio buttons)
								within a larger form. See an{" "}
								<a href="#overview-of-form-elements">Overview of form elements</a> above.
							</>,
							<>
								You need to provide a consistent structure for labeling, help text, and error
								messages associated with each form element.
							</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>FormElementWrapper</Code> is primarily used within{" "}
						<GatsbyLink to="/lab/components/Forms and input/Form">Form</GatsbyLink> to organize
						individual input fields. It can also be used within{" "}
						<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink> or dialogs containing
						forms.
					</Paragraph>

					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>FormElementWrapper</Code> to existing components for visual
								consistency.
							</>,
							<>
								<b>Alignment</b>: Align the <Code>FormElementWrapper</Code> vertically with other
								form elements on the page, adhering to the{" "}
								<GatsbyLink to="/lib/components/Structure/Grid and Grid.Section">
									Grid and Grid.Section
								</GatsbyLink>{" "}
								layout defined by your design system.
							</>,

							<>
								<b>Optional vs. Mandatory</b>:
								<Ul
									items={[
										<>Clearly indicate required fields with "(required)" or an asterisk (*).</>,
										<>
											If most fields are optional, only mark required fields. If most are required,
											mark optional fields.
										</>,
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"The form element does not require a label, help text, or error message display.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							<>
								Ensure that all elements within the <Code>FormElementWrapper</Code> (labels, inputs,
								help text, error messages) have sufficient color contrast and are easily
								distinguishable.
							</>,
							<>
								If errors are present after form submission, automatically move focus to the first
								error message.
							</>,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Ul
						items={[
							<>
								Ensure that all input fields have associated labels that are visible and
								programmatically linked.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Write clear and concise labels (1-3 words) that accurately describe the expected input.",
							"Use sentence case (capitalize the first word only).",
							"Avoid punctuation at the end of labels.",
							<>Omit unnecessary words like "the", "a", or "an". </>,
							<>
								Prioritize descriptive labels over instructional ones (e.g., "Email address" instead
								of "Enter your email address").
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => {
	const [value, setValue] = useState("");
	const [value2, setValue2] = useState("");

	return (
		<>
			<FormElementWrapper label="Label" name="name">
				<InputField value={value} onChange={setValue} />
			</FormElementWrapper>
			<FormElementWrapper label="Label with tooltip" name="name" tooltip="Some further explanation">
				<InputField value={value2} onChange={setValue2} />
			</FormElementWrapper>
		</>
	);
};

const GroupExample = () => {
	const [value, setValue] = useState("");

	return (
		<FormElementWrapper label="Label" name="name" isGroup>
			{(formControlProps) => (
				<>
					<InputField {...formControlProps} value={value} onChange={setValue} />
					<Button onClick={() => console.log("clicked")}>Button</Button>
				</>
			)}
		</FormElementWrapper>
	);
};

const SingleSelectExample = () => {
	const items = [
		{ title: "Apple", value: 1 },
		{ title: "Banana", value: 2 },
		{ title: "Pear", value: 3 },
	];
	const [selected, setSelected] = useState<number | undefined>();

	return (
		<FormElementWrapper label="Fruit" name="Fruit">
			<Select
				items={items}
				value={selected}
				onChange={setSelected}
				noDefaultOption
				placeholder="Some placeholder"
			/>
		</FormElementWrapper>
	);
};

const MultiSelectExample = () => {
	const items = [
		{ value: 0, title: "Apple" },
		{ value: 1, title: "Banana" },
		{ value: 2, title: "Blueberry" },
		{ value: 3, title: "Cherry" },
		{ value: 4, title: "Grape" },
		{ value: 5, title: "Guava" },
		{ value: 6, title: "Lemon" },
		{ value: 7, title: "Lime" },
		{ value: 8, title: "Orange" },
		{ value: 9, title: "Peach" },
		{ value: 10, title: "Pear" },
	];
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	return (
		<FormElementWrapper label="Fruit" name="Fruit">
			<Select
				items={items}
				value={selectedItems}
				onChange={setSelectedItems}
				noDefaultOption
				bulkActions
				placeholder="Some placeholder"
			/>
		</FormElementWrapper>
	);
};

const CheckboxExample = () => {
	const [selected, setSelected] = useState<string[]>([]);

	return (
		<FormElementWrapper label="Fruit" name="Fruit">
			<CheckboxGroup onChange={setSelected} value={selected}>
				<CheckboxGroup.Checkbox value="Apple">Apple</CheckboxGroup.Checkbox>
				<CheckboxGroup.Checkbox value="Banana">Banana</CheckboxGroup.Checkbox>
				<CheckboxGroup.Checkbox value="Pear">Pear</CheckboxGroup.Checkbox>
			</CheckboxGroup>
		</FormElementWrapper>
	);
};

const RadiobuttonExample = () => {
	const [radioValue, setRadioValue] = useState("Apple");

	return (
		<FormElementWrapper label="Fruit" name="Fruit">
			<Radios value={radioValue} onChange={setRadioValue}>
				<Radios.Radio value="Apple">Apple</Radios.Radio>
				<Radios.Radio value="Banana">Banana</Radios.Radio>
				<Radios.Radio value="Pear">Pear</Radios.Radio>
			</Radios>
		</FormElementWrapper>
	);
};

const HelptextExample = () => {
	const [value, setValue] = useState("");
	const [value2, setValue2] = useState("");

	return (
		<>
			<H2>Below (default)</H2>
			<FormElementWrapper
				label="Label"
				name="name"
				helptext="Helptext to understand the context of the form control better"
			>
				<InputField value={value} onChange={setValue} />
			</FormElementWrapper>
			<H2>Above</H2>
			<FormElementWrapper
				label="Label"
				name="name"
				helptext="Helptext to understand the context of the form control better"
				helptextPosition="above"
			>
				<InputField value={value2} onChange={setValue2} />
			</FormElementWrapper>
		</>
	);
};

const InvalidStateExample = () => {
	const [value, setValue] = useState("");
	const [value2, setValue2] = useState("");

	return (
		<>
			<FormElementWrapper label="Invalid state" name="name" invalid>
				<InputField value={value2} onChange={setValue2} />
			</FormElementWrapper>
			<FormElementWrapper
				label="Invalid state with error"
				name="name"
				error="Some error message to let the user know what's wrong"
				invalid
			>
				<InputField value={value} onChange={setValue} />
			</FormElementWrapper>
		</>
	);
};

const HelptextAndInvalidStateExample = () => {
	const [value, setValue] = useState("");

	return (
		<>
			<FormElementWrapper
				label="Helptext and invalid state"
				name="name"
				helptext="Helptext to understand the context of the form control better"
				error="Some error message to let the user know what's wrong"
				invalid
			>
				<InputField value={value} onChange={setValue} />
			</FormElementWrapper>
		</>
	);
};

const HorizontalExample = () => {
	const [value, setValue] = useState("");
	const [value2, setValue2] = useState("");

	return (
		<>
			<FormElementWrapper horizontal label="Label" name="name">
				<InputField value={value} onChange={setValue} />
			</FormElementWrapper>
			<FormElementWrapper
				horizontal
				label="Label with tooltip"
				tooltip="Some further explanation"
				name="name"
			>
				<InputField value={value2} onChange={setValue2} />
			</FormElementWrapper>
		</>
	);
};

const SlugsHelptextAndInvalidStatExample = () => {
	const [value, setValue] = useState("");

	return (
		<>
			<FormElementWrapper
				label="Slugs, helptext and invalid state"
				name="name"
				helptext="Helptext to understand the context of the form control better"
				error="Some error message to let the user know what's wrong"
				invalid
			>
				<InputFieldWithSlug
					aria-label="My accessible input component"
					placeholder="Placeholder"
					name="some input"
					value={value}
					onChange={setValue}
					leftSlug="Count:"
					rightSlug="items"
					fullWidth
				/>
			</FormElementWrapper>
		</>
	);
};

const TooltipVariantsExample = () => {
	const [value, setValue] = useState("");

	return (
		<>
			<FormElementWrapper
				label="Label with tooltip variant"
				name="name"
				tooltip={
					<Tooltip variant={{ type: "icon-only" }} content="Some further explanation">
						<Icon>
							<IconPotentialIssue />
						</Icon>
					</Tooltip>
				}
			>
				<InputField value={value} onChange={setValue} />
			</FormElementWrapper>
		</>
	);
};

const ExampleInputField = (props: Omit<InputFieldProps, "onChange" | "value">) => {
	const [value, setValue] = useState("");
	return <InputField value={value} onChange={setValue} {...props} />;
};
